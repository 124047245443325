import React, { useState } from 'react'
import axios from 'axios'

const ContactForm = () => {
  
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [pno, setPno] = useState('')
  const [cate, setCate] = useState('')
  const [desc, setDesc] = useState('')
 
  const contactUs = async (e) =>{
    e.preventDefault()
    try {
      const res = await axios.post('https://breakdownassistant.com/register/send/mail', {
          fname: firstName,
          lname: lastName,
          email: email,
          pno: pno,
          cate: cate,
          desc: desc
      })
      alert(res.data.msg)
      setFirstName('')
      setLastName('')
      setEmail('')
      setPno('')
      setCate('')
      setDesc('')
    } catch (error) {
        if (error.response) {
          alert(error.response.data.msg)
        }
    }
  }

  return (
    <form onSubmit={contactUs} className='bg-[#fff] w-[80%] sm:w-[90%] mx-auto py-16 sm:py-10 px-14 sm:px-3'>
        <div>
            <h2 className='text-center font-medium sm:text-xl text-4xl'>Get in touch</h2>
            <p className='text-center mb-16 text-[#000000b3] sm:text-sm font-normal'>Fill out the form below, and our team will get back to you as soon as possible.</p>
        </div>
    <div className='w-full flex gap-4 md:flex-col justify-between mb-12 sm:mb-[16px]'>
      <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}  style={{border: '1px solid #0000004d'}} className='w-[45%] md:w-[100%] h-[60px] pl-6 rounded-[10px] focus:outline-none' placeholder='First Name' />
      <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} style={{border: '1px solid #0000004d'}}  className='w-[45%] md:w-[100%] h-[60px] pl-6 rounded-[10px] focus:outline-none' placeholder='Last Name' />
    </div>
    <div className='w-full flex gap-4 md:flex-col justify-between sm:mb-[16px] mb-12'>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}  style={{border: '1px solid #0000004d'}} className='w-[45%] md:w-[100%] h-[60px] pl-6 rounded-[10px] focus:outline-none' placeholder='Email' />
      <input type="number" value={pno} onChange={(e) => setPno(e.target.value)}  style={{border: '1px solid #0000004d'}}  className='w-[45%] md:w-[100%] h-[60px] pl-6 rounded-[10px] focus:outline-none' placeholder='Phone Number' />
    </div>
    <div className='w-full flex justify-between mb-12 sm:mb-[16px]'>
     
      <select value={cate} onChange={(e) => setCate(e.target.value)} style={{border: '1px solid #0000004d'}} className='category-select pl-6 w-full h-[60px] rounded-[10px] pr-6 bg-[#FFFFFF] focus:outline-none' name="category" id="category">
        <option value="" className='pl-6'>Select Category</option>
        <option value="Client" className='pl-6'>Client</option>
        <option value="Service Provider" className='pl-6'>Service Provider</option>
      </select>
    </div>
    <div>
      <textarea value={desc} onChange={(e) => setDesc(e.target.value)}  style={{border: '1px solid #0000004d'}} className='w-full pl-6 pt-5 pb-32 rounded-[10px] focus:outline-none' placeholder='Your Text'></textarea>
    </div>
    <input type="submit" value="Submit"  className='bg-[#6869ff] rounded-[10px] text-white w-full h-[80px] mt-12 sm:mt-[22px]' />
  </form>
  )
}

export default ContactForm